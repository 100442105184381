<template>
    <div class="home-page">
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'Home',
    created() {
        this.resetQuizData();

        this.changeStepFund({
            value: 50000,
            increase: false,
            change: true,
        });
    },
    methods: {
        ...mapActions('quiz', [
            'resetQuizData',
            'changeStepFund',
        ]),
    },
};
</script>
